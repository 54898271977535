// entry point for unauthenticated, server-side generated pages
import 'core-js';
import 'styles/unauthenticated';
import 'font-awesome/css/font-awesome.css';
import ReactDOM from 'react-dom';
import React from 'react';
import UnauthenticatedNavBar from 'components/UnauthenticatedNavBar';
import UnauthenticatedBrowserAlerts from 'components/UnauthenticatedBrowserAlerts';

const state = window.__INITIAL_STATE;

window.onload = () => {
  const headerDest = document.getElementById('header');
  if (headerDest) {
    ReactDOM.render(
      <UnauthenticatedNavBar
        customer={state.customer}
        webRegistration={state.webRegistration}
        version={state.version}
      />,
      headerDest
    );
  }
  const alertsDest = document.getElementById('alert-container');
  if (alertsDest) {
    ReactDOM.render(<UnauthenticatedBrowserAlerts />, alertsDest);
  }
};

/**
 * Unregister service workers if user gets to login page.
 *
 * Unregistering of service workers on on login page is required as
 * user-specific application index page is cached withing service worker.
 * Clearing of service worker prevents newly logged user from obtaining index
 * page for previously logged user. This also prevents service workers for
 * user-portal and employee-portal to be installed simultaneously.
 **/
if (window.location.pathname === '/log_in' && 'serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(r => {
    return Promise.all(r.map(reg => reg.unregister()));
  });
}
