import React from 'react';
import {
  deprecatedMessage,
  isDeprecatedBrowser,
  isUnsupportedBrowser,
  unsupportedMessage
} from 'utils/browserSupport';

export default function UnauthenticatedBrowserAlerts() {
  return (
    <>
      {isDeprecatedBrowser() && (
        <Alert text={deprecatedMessage} level="deprecated" />
      )}
      {isUnsupportedBrowser() && (
        <Alert text={unsupportedMessage} level="unsupported" />
      )}
    </>
  );
}

function Alert(props: { text: string; level: 'unsupported' | 'deprecated' }) {
  const { text, level } = props;
  return (
    <div className="form-group">
      <div className="col-sm-offset-4 col-sm-7 col-md-5 col-lg-4">
        <div
          className={`unsupported-browser-container unsupported-browser-${level}`}
        >
          <div className="content">
            <i className="fa fa-warning alerts-item-icon" />
            {text}
          </div>
        </div>
      </div>
    </div>
  );
}
