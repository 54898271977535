type LogoImage = {
  logoDev: string;
  logo: string;
  width: number;
  height: number;
};

let logoImage: LogoImage = {
  logoDev: require('images/logo-dev.png'),
  logo: require('images/logo.png'),
  width: 133,
  height: 36
};

export function setLogoImage(newLogoImage: LogoImage) {
  logoImage = newLogoImage;
}
export const getLogoImage = () => {
  return logoImage;
};
