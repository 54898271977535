import { t } from 'ttag';
export const deprecatedMessage = t`Za optimalno delovanje aplikacije priporočamo uporabo novejšega brskalnika.`;
export const unsupportedMessage = t`Brskalnik ni podprt, za pravilno delovanje aplikacije uporabite novejši brskalnik.`;
export function isDeprecatedBrowser() {
  const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
  const trident = ua.indexOf('Trident/'); // IE 11

  return trident > 0;
}

export function isUnsupportedBrowser() {
  const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older

  return msie > 0;
}
